/* banner wrapper */
.heroWrap-loader,
.bannerWrap-loader {
  position: relative;
  background-color: #efefef;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: 1;
}

.banner h1 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 3rem;
  text-align: left;
  color: #fff;
  z-index: 10;
}
section.banner,
section.banner h1 {
  margin: 0 auto;
  width: 100%;
}
.heroWrap,
.bannerWrap,
section.banner {
  min-height: 366px;
}
.heroWrap,
.bannerWrap {
  position: relative;
  background-color: #000;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: 1;
}
.bannerWrap:after {
  content: '';
  background-color: #000;
  opacity: 0.5;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
}

.hero h1 {
  width: 38%;
  padding-top: 5rem;
  font-size: 3rem;
  text-align: center;
  font-weight: bold;
  color: black;
}
.hero h3,
.hero h6 {
  width: 38%;
  padding-top: 10px;
  font-size: 1.5rem;
  text-align: center;
  color: black;
}
.hero .btnWrap {
  width: 38%;
}
section.hero {
  margin: 0 auto;
  width: 100%;
}
.btnWrap {
  padding: 1rem 0;
  text-align: center;
}
