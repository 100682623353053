.spincontainer {
  position: relative;
}

.invalidBackground{
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  z-index: 999;
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow: hidden;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 10px
}

.bottleContainer {
  width: 100vw;
  height: auto;
  max-width: 410px;
  margin: 0 auto;
  padding-top: 20rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottleWrapper {
  width: 4.8rem;
  z-index: 2;
}

.zoomout {
  animation: fade-out-keyframes 0.5s cubic-bezier(.21,-0.07,.51,-0.21) forwards;
}
.moveup {
  animation: move-up 0.5s cubic-bezier(.21,-0.07,.51,-0.21) forwards;
}

.tandc { 
  position: relative;
  bottom: -222px;
  width: 100%;
  padding: 10px 0;
}

.tandc p {
  color: white;
  text-align: center;
  font-size: 14px;
}

.tandc a {
  color: white!important;
}

/* .mealDealArrow {
  position: absolute;
  margin: 0 auto;
  top: 0;
  margin-top: 5px;
  max-width: 345px;
  transition: filter 0.1s ease-out;
} */

.addMarginTopBottle {
  margin-top: 10px;
}

.addCustomMargin {
  margin-top: 11px;
}

.zIndexUp {
  z-index: 5;
}

.spinButtonBottle {
  width: 100%;
  position: absolute;
  text-align: center;
  z-index: 5;
  top: 74%;
  opacity: 0;
}
.prizetowinfr {
  text-align: center;
  position: absolute;
  bottom: -180px;
  right: 0%;
  max-width: 135px;
  width: 100%;
  z-index: 2;
}
.displayMessageBottle {
  position: absolute;
  width: 100%;
  top: 170px;
  opacity: 1;
  z-index: -6;
}

.placeinfront {
  z-index: 20!important;
}

.debugOption {
  margin: 5px;
  background: #f4f4f4;
  border: 1px solid #b9b9b9;
  min-width: 192px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top: 0;
  padding: 5px;
  z-index: 1000;
}

.debugOption input {
  font-size: 0.8rem;
  width: 100%;
}

.debugOption button {
  font-size: 0.8rem;
  width: 100%;
}

.displayMessageEmail{
  text-align: center;
  padding: 12px 20px;
  border-radius: 20px;
  border: 4px solid #006638;
  width: 100%;
  max-width: 265px;
  font-weight: bold;
  color: #0b8a3d!important;
  font-size: 1rem;
  margin: 0 auto;
  position: relative;
  bottom: 15px;
  line-height: 1.3rem;
  opacity: 0;
}

.displayMessageEmailAnim {
  animation: fade-in-keyframes 0.35s ease-in forwards;
  animation-delay: 2.5s;
}

.hidden {
  display: none;
}

.fade-out {
  animation: fade-out-keyframes 0.23s ease-in 0s forwards;
}

.rejour-anim {
  animation: fade-in-keyframes 0.23s ease-in 2.5s forwards;
}

@keyframes move-up {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(-110%);
  }
}
@keyframes fade-out-keyframes {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0);
  }
}

@keyframes fade-in-keyframes {
  from {
    opacity: 0;
    bottom: 35px;
  }
  to {
    opacity: 1;
    bottom: 15px;
  }
}

/* @media only screen and (max-width: 376px) {
  .mealDealArrow {
    max-width: 270px;
  }
  .bottleContainer {
    padding-top: 11rem;

  }
} */
@media only screen and (max-width: 321px) {
  .wheelWrapper {
    width: 4.2rem;
    max-width: 405px;
  }
}