@font-face {
  font-family: 'SourceSansPro-Bold';
  src: url('/assets/stormborn/fonts/Source_Sans_Pro/SourceSansPro-Bold.ttf')
    format('opentype');
}
@font-face {
  font-family: 'SourceSansPro-Regular';
  src: url('/assets/stormborn/fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf')
    format('opentype');
}
@font-face {
  font-family: 'SubwaySixInch';
  src: url('/assets/stormborn/fonts/Subway/SubwaySixInchOffice.otf')
    format('opentype');
}
@font-face {
  font-family: 'SubwayFootlong';
  src: url('/assets/stormborn/fonts/Subway/SubwayFootlongOffice.otf')
    format('opentype');
}
@font-face {
  font-family: 'SubwaySansCondBold';
  src: url('/assets/stormborn/fonts/Subway/SubwaySansCond-Bold.otf')
    format('opentype');
}
.sans-pro-bold {
  font-family: 'SourceSansPro-Bold' !important;
}
.sans-pro-regular {
  font-family: 'SourceSansPro-Regular' !important;
}
.subway-six-inch {
  /* for context text, responses etc */
  font-family: 'SubwaySixInch' !important;
}
.subway-footlong {
  /* for title, button etc */
  font-family: 'SubwayFootlong' !important;
}
.subway-sans-cond-bold {
  /* for big button promotion */
  font-family: 'SubwaySansCondBold' !important;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: scroll !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*!
 * Start Bootstrap - Simple Sidebar (https://startbootstrap.com/template-overviews/simple-sidebar)
 * Copyright 2013-2019 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap-simple-sidebar/blob/master/LICENSE)
 */

#thq-main-body {
  /* padding-left: 2rem !important; */
  padding-top: 4.25rem !important;
  padding-bottom: 1.25rem !important;
  margin: auto;
  overflow: scroll !important;
  /* border-style:dashed;
  border-width: 1px;
  border-color: red; */
}

#thq-sidebar-wrapper {
  min-height: 100vh;
  margin-left: -12rem;
  transition: margin 0.25s ease-out;
}

#thq-sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#thq-sidebar-wrapper .list-group {
  width: 12rem;
}

#thq-content-wrapper {
  /* border-style:dashed;
  border-width: 1px;
  border-color: red; */
  min-width: 100vw;
}

#thq-main-wrapper.toggled #thq-sidebar-wrapper {
  margin-left: 0;
}

/* .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  background-color: #d5ebdb !important;
}
.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  background-color: #ef9a9a !important;
} */

/* @media (min-width: 768px) {
  #thq-sidebar-wrapper {
    margin-left: 0;
  }

  #thq-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  #thq-main-wrapper.toggled #thq-sidebar-wrapper {
    margin-left: -12rem;
  }
} */

#thq-sidebar-wrapper #thq-sidebar-modules {
  /* padding: 1.25rem 1.25rem; */
  font-size: 1.2rem;
}

#thq-sidebar-wrapper #thq-sidebar-modules p {
  font-size: 1rem;
}

#thq-sidebar-wrapper #thq-sidebar-modules a {
  color: #999999;
  text-decoration: none;
}

#thq-main-wrapper .border-right {
  border-right: 1px solid #efefef !important;
}

.navbar-brand {
  display: inline !important;
}
.toggled .navbar-brand {
  display: none !important;
}
.carousel .slide {
  background: transparent !important;
  position: relative;
  top: 0;
  bottom: 0;
  height: 100%;
}
.slider-container {
  width: 100%;
  height: 100%;
}
.carousel .control-dots .dot {
  transition: opacity 0.25s ease-in;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
  background: #fff !important;
  opacity: 0.8 !important;
  border-radius: 50%;
  width: 9px !important;
  height: 9px !important;
  cursor: pointer;
  display: inline-block;
  margin: 0 8px !important;
}
.carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
  background: #008938 !important;
  outline: none !important;
}

.carousel.carousel-slider .control-arrow {
  justify-content: center !important;
  color: #fff;
  font-size: 35px !important;
  align-content: center;
  align-items: center;
  top: -17% !important;
  bottom: -17% !important;
  margin-top: 17% !important;
  margin-bottom: 17% !important;
  padding-bottom: 7px !important;
  padding-right: 9px !important;
  padding-left: 9px !important;
  padding-top: 7px !important;
}
.MuiInput-root.MuiInput-underline:before {
  border-bottom: 0px solid rgba(97, 97, 97, 1) !important;
  border-radius: 5px !important;
}
.MuiInput-root.MuiInput-underline:hover {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.23) !important;
  border-radius: 5px !important;
}
.MuiInputBase-root:after {
  border: 0px solid #008938 !important;
}
a {
  text-decoration: none;
}
a:hover {
  color: inherit !important;
}

.link-hover:hover {
  color: #008938 !important;
}

.cookies-settings:hover {
  opacity: 0.7 !important;
}

/* *::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
*::-webkit-scrollbar-track {
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.01);
  background-color: rgba(0, 0, 0, 0.01);
}
*::-webkit-scrollbar-thumb {
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.15);
  background-color: rgba(0, 0, 0, 0.15);
  padding: 5px;
} */

.atom-alert {
  padding-left: 5px !important;
  padding-right: 5px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

/* .alert-light {
  color: transparent !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.alert-danger {
  color: #721c24 !important;
  background-color: #fff9fa !important;
  border-color: #fff9fa !important;
} */

.is-invalid {
  border-color: #ef9a9a !important;
  background-image: none !important;
}

.is-invalid:focus,
.form-control:focus {
  box-shadow: 0 0 0 0.05rem #c3e0ff !important;
}

.hidden {
  display: none;
}

.green-link a {
  justify-content: center !important;
  align-items: center !important;
  margin: auto !important;
  color: #038d43 !important;
  text-decoration: underline !important;
  cursor: pointer !important;
}

button a {
  color: #ffffff !important;
}

button {
  margin-right: 5px !important;
}

button:focus {
  outline: 0 !important;
}

/* recaptcha */
#g-recaptcha {
  margin: 10px auto;
  text-align: center;
}
#g-recaptcha > div {
  width: 100% !important;
}

/* className="productSelect" */
.productSelect {
  padding: 5px;
  background: #fff;
  cursor: pointer;
  border-radius: 5px;
}
.productSelect:hover {
  background: #f44336;
}
.productSelect-active-hb,
.productSelect-active-ty,
.productSelect-active-df {
  background: #000;
}
/* .MuiInputBase-root {
  background: #ddebdd;
  border-radius: 5px;
}
.Mui-error .MuiInputBase-input,
.Mui-error .MuiInput-formControl {
  background: #ef9a9a;
  border-radius: 5px;
} */

/* banner wrapper */
.heroWrap-loader,
.bannerWrap-loader {
  position: relative;
  background-color: #efefef;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: 1;
}

.banner h1 {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-size: 3rem;
  text-align: left;
  color: #fff;
  z-index: 10;
}
section.banner,
section.banner h1 {
  margin: 0 auto;
  width: 100%;
}
.heroWrap,
.bannerWrap,
section.banner {
  min-height: 366px;
}
.heroWrap,
.bannerWrap {
  position: relative;
  background-color: #000;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: 1;
}
.bannerWrap:after {
  content: '';
  background-color: #000;
  opacity: 0.5;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
}

.hero h1 {
  width: 38%;
  padding-top: 5rem;
  font-size: 3rem;
  text-align: center;
  font-weight: bold;
  color: black;
}
.hero h3,
.hero h6 {
  width: 38%;
  padding-top: 10px;
  font-size: 1.5rem;
  text-align: center;
  color: black;
}
.hero .btnWrap {
  width: 38%;
}
section.hero {
  margin: 0 auto;
  width: 100%;
}
.btnWrap {
  padding: 1rem 0;
  text-align: center;
}

.skeleton-container {
  width: 300px;
}
.skeleton-container .xs {
  width: 10%;
}
.skeleton-container .sm {
  width: 15%;
}
.skeleton-container .md {
  width: 30%;
}
.skeleton-container .lg {
  width: 60%;
}
.skeleton-container .xl {
  width: 100%;
}
.h10 {
  height: 10px;
}
.h15 {
  height: 15px;
}
.h30 {
  height: 30px;
}
.h60 {
  height: 60px;
}
.h120 {
  height: 120px;
}
.skeleton-container .line {
  float: left;
  margin: 5px;
  border-radius: 8px;
  background-image: linear-gradient(90deg, #f9f9f9 0px, #f4f4f4 40px, #f9f9f9 80px);
  background-size: 600px;
  -webkit-animation: shine-lines 3s infinite ease-out;
          animation: shine-lines 3s infinite ease-out;
}

.skeleton-container .line ~ .line {
  background-color: #f9f9f9;
}

.skeleton-container .clear {
  clear: left;
}

@-webkit-keyframes shine-lines {
  0% {
    background-position: -100px;
  }
  40%, 100% {
    background-position: 500px;
  }
}

@keyframes shine-lines {
  0% {
    background-position: -100px;
  }
  40%, 100% {
    background-position: 500px;
  }
}

.st1 {
  fill: #aa2239;
}

.spincontainer {
  position: relative;
}

.invalidBackground{
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  z-index: 999;
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow: hidden;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 10px
}
.invalidBackgroundsmall {
  font-weight: 300;
  font-size: 1.3rem;
  margin: 0;
}

.invalidBackgroundbold {
 font-weight: 800;
 font-size: 1.7rem;
}

.wheelContainer {
  width: 100vw;
  height: auto;
  max-width: 400px;
  margin: 0 auto;
  padding: 12rem 0 5rem 0;
  position: relative;
  display: flex;
  justify-content: center;
}

.dorito-footer {
  width: 100%;
  position: relative;
  top: -5px;
}

.displayMessageBottle {
  position: absolute;
  width: 100%;
  top: 36.6%;
  opacity: 1;
  z-index: -6;
}

.dark-background {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100vw;
  top: 0;
  z-index: 10;
  height: 672px;
}

.zoomout {
  -webkit-animation: fade-out-keyframes 0.5s cubic-bezier(.21,-0.07,.51,-0.21) forwards;
          animation: fade-out-keyframes 0.5s cubic-bezier(.21,-0.07,.51,-0.21) forwards;
}
.moveup {
  -webkit-animation: move-up 0.5s cubic-bezier(.21,-0.07,.51,-0.21) forwards;
          animation: move-up 0.5s cubic-bezier(.21,-0.07,.51,-0.21) forwards;
}

.tandc p {
  text-align: center;
  max-width: 580px;
  /* margin: 40px auto 0; */
  font-size: 14px;
  position: relative;
  /* bottom: -50px; */
}

.tandcwheel {
  text-align: center;
  max-width: 580px;
  margin: 0px auto 0;
  font-size: 14px;
  position: relative;
  bottom: -50px;
}

.mealDealArrow {
  position: absolute;
  margin: 0 auto;
  top: 0;
  margin-top: 5px;
  max-width: 250px;
}

.mealDealArrownl {
  position: absolute;
  margin: 0 auto;
  top: 0;
  margin-top: 5px;
  max-width: 327px!important;
  transition: -webkit-filter 0.1s ease-out;
  transition: filter 0.1s ease-out;
  transition: filter 0.1s ease-out, -webkit-filter 0.1s ease-out;
}

.addMarginTop {
  margin-top: 42px;
}

.fr-border {
  position: absolute;
  background-image: url("/assets/stormborn/spinthewheel/bgrepeat.png");
  height: 20px;
  width: 100%;
  background-size: inherit;
}

.fr-border-top {
  top: 5px;
  -webkit-transform: scaleY(-1);
          transform: scaleY(-1);
  z-index: 200;
}

.fr-border-bottom {
  bottom: 5px;
  z-index: 200;
}

.addCustomMargin {
  margin-top: 14px;
}

.zIndexUp {
  z-index: 5;
}

.spinButton {
  position: absolute;
  text-align: center;
  top: 48%;
  max-width: 180px;
  margin: 0 auto;
  left: 0;
  right: 0;
}

.buttonStyle {
  width: 100%;
  opacity: 1;
  max-width: 210px;
  cursor: pointer;
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-filter: drop-shadow(0px 3px 2px #333333) brightness(1.1);
          filter: drop-shadow(0px 3px 2px #333333) brightness(1.1);
  transition: -webkit-filter 0.1s ease-out;
  transition: filter 0.1s ease-out;
  transition: filter 0.1s ease-out, -webkit-filter 0.1s ease-out
}

.buttonStyle:hover {
  -webkit-filter: drop-shadow(0px 3px 2px #333333) brightness(1.2);
          filter: drop-shadow(0px 3px 2px #333333) brightness(1.2);
}

.buttonStyle:active {
  -webkit-filter: drop-shadow(0px 3px 2px #333333) brightness(0.9);
          filter: drop-shadow(0px 3px 2px #333333) brightness(0.9);
  margin-top: 2px;
  -webkit-transform: scale(0.96);
          transform: scale(0.96);
}

.prizetowin {
  text-align: center;
  position: absolute;
  bottom: -28px;
  width: 90%;
  z-index: 2;
}
.prizetowinfr {
  text-align: center;
  position: absolute;
  bottom: -80px;
  max-width: 220px;
  width: 90%;
  z-index: 2;
}
.displayMessage {
  position: absolute;
  width: 100%;
  top: -50px;
  z-index: -6;
}

.placeinfront {
  z-index: 1!important;
}

.debugOption {
  margin: 5px;
  background: #f4f4f4;
  border: 1px solid #b9b9b9;
  min-width: 192px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top: 0;
  padding: 5px;
  z-index: 1000;
}

.debugOption input {
  font-size: 0.8rem;
  width: 100%;
}

.debugOption button {
  font-size: 0.8rem;
  width: 100%;
}

.displayMessageEmail{
  text-align: center;
  padding: 12px 20px;
  border-radius: 20px;
  border: 4px solid #006638;
  background-color: white!important;
  width: 100%;
  max-width: 265px;
  font-weight: bold;
  color: #0b8a3d!important;
  font-size: 1rem;
  margin: 0 auto;
  position: relative;
  bottom: 15px;
  line-height: 1.3rem;
  opacity: 0;
}

.displayMessageEmailAnim {
  -webkit-animation: fade-in-keyframes 0.35s ease-in forwards;
          animation: fade-in-keyframes 0.35s ease-in forwards;
  -webkit-animation-delay: 2.5s;
          animation-delay: 2.5s;
}

.hidden {
  display: none;
}

.rejour-button {
  background: #038938;
  color: white;
  font-weight: bold;
  border: 4px solid white;
  border-radius: 18px;
  box-shadow: 0px 3px 1px 2px #004215;
  font-size: 1.1rem;
  position: relative;
  right: -50%;
  top: 2vh;
  max-width: 170px;
  width: 100%;
  height: 60px;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.rejour-anim {
  -webkit-animation: fade-in-keyframes 0.23s ease-in 2.5s forwards;
          animation: fade-in-keyframes 0.23s ease-in 2.5s forwards;
}

.fade-in {
  -webkit-animation: fade-in-keyframesv2 0.35s ease-in 0s forwards;
          animation: fade-in-keyframesv2 0.35s ease-in 0s forwards;
}

.wheelPointer {
  position: absolute;
  width: 100%;
  max-width: 65px;
  margin: 0 auto;
  top: 201px;
  left: -5px;
  bottom: 0;
  right: 0;
  z-index: 2;
}

.wheelPointernl {
  position: absolute;
  width: 100%;
  max-width: 88px;
  margin:0 auto;
  top: 211px;
  left: -5px;
  bottom: 0;
  right: 0;
  z-index: 2;
}

.fr-front-wheel-container {
  position: absolute;
  top: 45px;
  width: 100vw;
  height: 100vh;
  background-color: black;
  display: flex;
  z-index: 100;
  height: 590px;
  justify-content: center;
}

.fr-front-wheel {
  flex-direction: column;
  width: 100%;
  max-width: 420px;
  position: relative;
  top: 0;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.fr-front-wheel-img {
  max-width: 420px;
  width: 100%;
}

.fr-front-wheel-btn {
  max-width: 200px;
  margin: 30px auto;
}
.fr-front-wheel-logo {
  max-width: 90px;
  margin: 0px auto 10px;
}

@-webkit-keyframes move-up {
  from {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
  to {
    -webkit-transform: translateY(-110%);
            transform: translateY(-110%);
  }
}

@keyframes move-up {
  from {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
  to {
    -webkit-transform: translateY(-110%);
            transform: translateY(-110%);
  }
}
@-webkit-keyframes fade-out-keyframes {
  from {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
@keyframes fade-out-keyframes {
  from {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}


@-webkit-keyframes fade-in-keyframesv2 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


@keyframes fade-in-keyframesv2 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes fade-in-keyframes {
  from {
    opacity: 0;
    bottom: 35px;
  }
  to {
    opacity: 1;
    bottom: 15px;
  }
}

@keyframes fade-in-keyframes {
  from {
    opacity: 0;
    bottom: 35px;
  }
  to {
    opacity: 1;
    bottom: 15px;
  }
}

@media only screen and (max-width: 376px) {
  .wheelPointer {
    top: 176px;
    max-width: 65px;
  }
  .wheelPointernl {
    top: 172px;
    max-width: 65px;
  }
}

@media only screen and (max-width: 321px) {
  .wheelPointer {
    top: 171px;
    max-width: 50px;
  }
  .wheelPointernl {
    top: 171px;
    max-width: 50px;
  }
}
.spincontainer {
  position: relative;
}

.invalidBackground{
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  z-index: 999;
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow: hidden;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 10px
}

.bottleContainer {
  width: 100vw;
  height: auto;
  max-width: 410px;
  margin: 0 auto;
  padding-top: 20rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottleWrapper {
  width: 4.8rem;
  z-index: 2;
}

.zoomout {
  -webkit-animation: fade-out-keyframes 0.5s cubic-bezier(.21,-0.07,.51,-0.21) forwards;
          animation: fade-out-keyframes 0.5s cubic-bezier(.21,-0.07,.51,-0.21) forwards;
}
.moveup {
  -webkit-animation: move-up 0.5s cubic-bezier(.21,-0.07,.51,-0.21) forwards;
          animation: move-up 0.5s cubic-bezier(.21,-0.07,.51,-0.21) forwards;
}

.tandc { 
  position: relative;
  bottom: -222px;
  width: 100%;
  padding: 10px 0;
}

.tandc p {
  color: white;
  text-align: center;
  font-size: 14px;
}

.tandc a {
  color: white!important;
}

/* .mealDealArrow {
  position: absolute;
  margin: 0 auto;
  top: 0;
  margin-top: 5px;
  max-width: 345px;
  transition: filter 0.1s ease-out;
} */

.addMarginTopBottle {
  margin-top: 10px;
}

.addCustomMargin {
  margin-top: 11px;
}

.zIndexUp {
  z-index: 5;
}

.spinButtonBottle {
  width: 100%;
  position: absolute;
  text-align: center;
  z-index: 5;
  top: 74%;
  opacity: 0;
}
.prizetowinfr {
  text-align: center;
  position: absolute;
  bottom: -180px;
  right: 0%;
  max-width: 135px;
  width: 100%;
  z-index: 2;
}
.displayMessageBottle {
  position: absolute;
  width: 100%;
  top: 170px;
  opacity: 1;
  z-index: -6;
}

.placeinfront {
  z-index: 20!important;
}

.debugOption {
  margin: 5px;
  background: #f4f4f4;
  border: 1px solid #b9b9b9;
  min-width: 192px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top: 0;
  padding: 5px;
  z-index: 1000;
}

.debugOption input {
  font-size: 0.8rem;
  width: 100%;
}

.debugOption button {
  font-size: 0.8rem;
  width: 100%;
}

.displayMessageEmail{
  text-align: center;
  padding: 12px 20px;
  border-radius: 20px;
  border: 4px solid #006638;
  width: 100%;
  max-width: 265px;
  font-weight: bold;
  color: #0b8a3d!important;
  font-size: 1rem;
  margin: 0 auto;
  position: relative;
  bottom: 15px;
  line-height: 1.3rem;
  opacity: 0;
}

.displayMessageEmailAnim {
  -webkit-animation: fade-in-keyframes 0.35s ease-in forwards;
          animation: fade-in-keyframes 0.35s ease-in forwards;
  -webkit-animation-delay: 2.5s;
          animation-delay: 2.5s;
}

.hidden {
  display: none;
}

.fade-out {
  -webkit-animation: fade-out-keyframes 0.23s ease-in 0s forwards;
          animation: fade-out-keyframes 0.23s ease-in 0s forwards;
}

.rejour-anim {
  -webkit-animation: fade-in-keyframes 0.23s ease-in 2.5s forwards;
          animation: fade-in-keyframes 0.23s ease-in 2.5s forwards;
}

@-webkit-keyframes move-up {
  from {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
  to {
    -webkit-transform: translateY(-110%);
            transform: translateY(-110%);
  }
}

@keyframes move-up {
  from {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
  to {
    -webkit-transform: translateY(-110%);
            transform: translateY(-110%);
  }
}
@-webkit-keyframes fade-out-keyframes {
  from {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
@keyframes fade-out-keyframes {
  from {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}

@-webkit-keyframes fade-in-keyframes {
  from {
    opacity: 0;
    bottom: 35px;
  }
  to {
    opacity: 1;
    bottom: 15px;
  }
}

@keyframes fade-in-keyframes {
  from {
    opacity: 0;
    bottom: 35px;
  }
  to {
    opacity: 1;
    bottom: 15px;
  }
}

/* @media only screen and (max-width: 376px) {
  .mealDealArrow {
    max-width: 270px;
  }
  .bottleContainer {
    padding-top: 11rem;

  }
} */
@media only screen and (max-width: 321px) {
  .wheelWrapper {
    width: 4.2rem;
    max-width: 405px;
  }
}
.big-button-container {
    /* background-color: #008938; */
    display: flex;
    align-items: center;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    flex-direction: column;
}

.button-bg-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.bigButton {
    width: 100%;
    height: 100%;
    z-index: 2;   
}

.buttonImg {
    height: auto;
    max-width: 410px;
    width: 100%;
}

.button-content {
    position: absolute;
    left: 50.5%;
    top: 49%;
    -webkit-transform: translate(-50%,-50%) scale(1);
            transform: translate(-50%,-50%) scale(1);
    pointer-events: none;
}

.button-content p {
    margin: 0;
    line-height: 0.85;
    color: white;
    font-size: 3rem;
}

.relative { position: relative; }
.absolute { 
  position: absolute; 
  left: 0 ;
  z-index:-1; 
}

.yellow {
    color: #fbc114 !important;
}

.prize-reveal {
  opacity: 0;
  position: absolute;
  left: 50%;
  top: 49%;
  -webkit-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
  pointer-events: none;
}

.prize-reveal img {
  max-width: 145px;
  max-height: 145px;
  /* width: 100%;
  height: auto; */
}
.prize-incoming {
    position: absolute;
    z-index: 3;
    top: -210px;
    display: none;
}

.prize-incoming img {
    max-width: 300px;
    width: 100%;
}

.enlarge-anim {
  -webkit-animation: enlarge 0.6s ease-in-out infinite alternate;
          animation: enlarge 0.6s ease-in-out infinite alternate;
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  position: absolute;
}
.pushed-anim {
  -webkit-animation: pushed 0.35s cubic-bezier(0,.88,.96,1), glow 0.8s linear 1 forwards;
          animation: pushed 0.35s cubic-bezier(0,.88,.96,1), glow 0.8s linear 1 forwards;
  -webkit-transform-origin: center;
          transform-origin: center;
}

.fadeOut-anim {
  -webkit-animation: fadeOut 0.3s ease-out 0.3s forwards, pushedText 0.35s cubic-bezier(0,.88,.96,1);
          animation: fadeOut 0.3s ease-out 0.3s forwards, pushedText 0.35s cubic-bezier(0,.88,.96,1);
}
.fadeInText-anim {
  -webkit-animation: fadeIn 0.1s ease-in 1s forwards;
          animation: fadeIn 0.1s ease-in 1s forwards;
  opacity: 0;
}

.fadeIn-anim {
  -webkit-animation: fadeIn 0.1s ease-in 1s forwards;
          animation: fadeIn 0.1s ease-in 1s forwards;
}
.fadeOutSlow-anim {
  -webkit-animation: fadeOut 1s ease-out forwards;
          animation: fadeOut 1s ease-out forwards;
}
.slideIn-anim {
  -webkit-animation: slideIn 0.5s ease-out 0.5s forwards;
          animation: slideIn 0.5s ease-out 0.5s forwards;
  display: block!important;
}


.tandc, .tandc a, .flavorMessage {
    color: white;
} 

.tandc p {
  margin: 0 auto;
  padding: 0 14px;
}

.flavorMessage {
    font-weight: bold; 
    font-size: 1rem;
    position: relative;
    bottom: 5px;
    height: 165px;
}

.apostroContainer {
    display: flex;
    flex-direction: row;
}

.apostroContainer:nth-child(1){
    align-items: flex-end;
}
.apostroContainer:nth-child(1) span {
    line-height: 1;
}
.apostroContainer:nth-child(2){
    align-items: center;
}
.apostroContainer:nth-child(3){
    align-items: flex-start;
}

.apostroContainer:nth-child(3) span {
    line-height: 0.6;
}

.apostroContainShort {
    display: flex;
    flex-direction: row;
}

.apostroContainShort:nth-child(1){
    align-items: flex-end;
    margin-bottom: 8px;
}
.apostroContainShort:nth-child(1) span {
    line-height: 1;
}

.apostroContainShort:nth-child(2){
    align-items: flex-start;
}

.apostroContainShort:nth-child(2) span {
    line-height: 0.6;
}

.white{
    align-items: flex-start;
}
.white span {
    line-height: 0.6;
    color: white;
}

.centerTextContainer {
    margin-bottom: 5px;
}

.centerText{
    line-height: 0.6;
}

.flavorText {
    font-size: 34px;
}

.opacityzero {
    opacity: 0;
}

.opacityshow {
  opacity: 1;
}

.flavorTextMiddle {
    display: flex;
    line-height: 1;
    margin-bottom: 6px;
    color: #fbc114;
}

.apostroImg1 {
    width: 60px;
    height: 60px;
}
.apoOpen {
    margin-right: 10px;
}
.apoClose {
    margin-left: 10px;
}

.breakspace {
  margin-right: 5px;
}

/*=========================*/
/*===== Carousel BG =======*/
/*=========================*/

.buttonContent {
    width: 60px;
    height: 60px;
    font-size: 0.5rem;
}

/*=====================*/
/*===== Icon BG =======*/
/*=====================*/

.iconBgContainer {
    max-width: 400px;
    height: auto;
    position: absolute;
    top: 48%;
    z-index: -2;
    left: 48.5%;
}

.iconBg {
    position: absolute;
    width: auto;
    max-height: 90px;
    max-width: 90px;
    left: calc(50% - 25px);
    top: calc(50% - 25px);
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-animation: jiggle 1.5s cubic-bezier(.46,.16,.47,.84) infinite alternate;
            animation: jiggle 1.5s cubic-bezier(.46,.16,.47,.84) infinite alternate;
}

.iconBg:nth-child(1) {
    -webkit-transform: translate(148%,96%) rotate(13deg);
            transform: translate(148%,96%) rotate(13deg);
    max-height: 110px;
    max-width: 100px;
}
.iconBg:nth-child(2) {
    -webkit-transform: translateX(273%) translateY(115%) rotate(344deg);
            transform: translateX(273%) translateY(115%) rotate(344deg);
    max-height: 78px;
    max-width: 88px;
}
.iconBg:nth-child(3) {
    -webkit-transform: translateX(306%) translateY(143%) rotate(335deg);
            transform: translateX(306%) translateY(143%) rotate(335deg)
}
.iconBg:nth-child(4) {
    -webkit-transform: translateY(192%) rotate(-13deg);
            transform: translateY(192%) rotate(-13deg);
}
.iconBg:nth-child(5) {
    max-height: 90px;
    max-width: 80px;
    -webkit-transform: translateX(-113%) translateY(329%) rotate(-50deg);
            transform: translateX(-113%) translateY(329%) rotate(-50deg);
}
.iconBg:nth-child(6) {
    -webkit-transform: translateX(-234%) translateY(230%) rotate(-16deg);
            transform: translateX(-234%) translateY(230%) rotate(-16deg);
    max-height: 72px;
    max-width: 72px;
}
.iconBg:nth-child(7) {
    -webkit-transform: translate(-300%,72%);
            transform: translate(-300%,72%);
    max-height: 62px;
    max-width: 62px;
}
.iconBg:nth-child(8) {
    -webkit-transform: translate(-193%,-50%) rotate(13deg);
            transform: translate(-193%,-50%) rotate(13deg);
    max-width: 90px;
}
.iconBg:nth-child(9) {
    -webkit-transform: translate(-199%,-233%) rotate(35deg);
            transform: translate(-199%,-233%) rotate(35deg);
}
.iconBg:nth-child(10) {
    -webkit-transform: translate(-118%,-242%) rotate(-16deg);
            transform: translate(-118%,-242%) rotate(-16deg);
    max-width: 85px;
}
.iconBg:nth-child(11) {
    -webkit-transform: translate(11%,-230%) rotate(10deg);
            transform: translate(11%,-230%) rotate(10deg);
    max-width: 82px;
}
.iconBg:nth-child(12) {
    -webkit-transform: translate(146%,-283%) rotate(35deg);
            transform: translate(146%,-283%) rotate(35deg);
    max-width: 76px;
}
.iconBg:nth-child(13) {
    -webkit-transform: translate(190%,-88%) rotate(35deg);
            transform: translate(190%,-88%) rotate(35deg);
    max-width: 74px;
}

.iconBg:nth-child(14), .iconBg:nth-child(15), .iconBg:nth-child(16) {
  display: none;
}

@-webkit-keyframes jiggle {
  0%   { left:-24px; top:-18px;}
  25%  { left:-27px; top:-20px;}
  50%  { left:-25px; top:-23px;}
  75%  { left:-23px; top:-19px;}
  100% { left:-22px; top:-24px;}
}

@keyframes jiggle {
  0%   { left:-24px; top:-18px;}
  25%  { left:-27px; top:-20px;}
  50%  { left:-25px; top:-23px;}
  75%  { left:-23px; top:-19px;}
  100% { left:-22px; top:-24px;}
}

@-webkit-keyframes enlarge {
  0%   { -webkit-transform: scale(1.05); transform: scale(1.05);}
  100% { -webkit-transform: scale(1.3); transform: scale(1.3);}
}

@keyframes enlarge {
  0%   { -webkit-transform: scale(1.05); transform: scale(1.05);}
  100% { -webkit-transform: scale(1.3); transform: scale(1.3);}
}
@-webkit-keyframes pushed {
  0%   { -webkit-transform: scale(1); transform: scale(1);}
  50% { -webkit-transform: scale(0.93); transform: scale(0.93);}
  100% { -webkit-transform: scale(1); transform: scale(1);}
}
@keyframes pushed {
  0%   { -webkit-transform: scale(1); transform: scale(1);}
  50% { -webkit-transform: scale(0.93); transform: scale(0.93);}
  100% { -webkit-transform: scale(1); transform: scale(1);}
}
@-webkit-keyframes pushedText {
  0%   { -webkit-transform: scale(1) translate(-50%,-50%); transform: scale(1) translate(-50%,-50%);}
  50% { -webkit-transform: scale(0.93) translate(-50%,-50%); transform: scale(0.93) translate(-50%,-50%);}
  100% { -webkit-transform: scale(1) translate(-50%,-50%); transform: scale(1) translate(-50%,-50%);}
}
@keyframes pushedText {
  0%   { -webkit-transform: scale(1) translate(-50%,-50%); transform: scale(1) translate(-50%,-50%);}
  50% { -webkit-transform: scale(0.93) translate(-50%,-50%); transform: scale(0.93) translate(-50%,-50%);}
  100% { -webkit-transform: scale(1) translate(-50%,-50%); transform: scale(1) translate(-50%,-50%);}
}
@-webkit-keyframes glow {
  0%   {-webkit-filter: drop-shadow(0px 0px 0px yellow);filter: drop-shadow(0px 0px 0px yellow);}
  10%   {-webkit-filter: drop-shadow(0px -9px 20px yellow);filter: drop-shadow(0px -9px 20px yellow);}
  20%   {-webkit-filter: drop-shadow(10px -8px 20px yellow);filter: drop-shadow(10px -8px 20px yellow);}
  40%   {-webkit-filter: drop-shadow(10px 10px 20px yellow);filter: drop-shadow(10px 10px 20px yellow);}
  55%   {-webkit-filter: drop-shadow(0px 12px 20px yellow);filter: drop-shadow(0px 12px 20px yellow);}
  65%   {-webkit-filter: drop-shadow(-10px 12px 21px yellow);filter: drop-shadow(-10px 12px 21px yellow);}
  80%   {-webkit-filter: drop-shadow(-12px -6px 23px yellow);filter: drop-shadow(-12px -6px 23px yellow)}
  90%   {-webkit-filter: drop-shadow(-3px -6px 25px yellow);filter: drop-shadow(-3px -6px 25px yellow)}
  100% {-webkit-filter: drop-shadow(0px 0px 32px yellow);filter: drop-shadow(0px 0px 32px yellow);}
}
@keyframes glow {
  0%   {-webkit-filter: drop-shadow(0px 0px 0px yellow);filter: drop-shadow(0px 0px 0px yellow);}
  10%   {-webkit-filter: drop-shadow(0px -9px 20px yellow);filter: drop-shadow(0px -9px 20px yellow);}
  20%   {-webkit-filter: drop-shadow(10px -8px 20px yellow);filter: drop-shadow(10px -8px 20px yellow);}
  40%   {-webkit-filter: drop-shadow(10px 10px 20px yellow);filter: drop-shadow(10px 10px 20px yellow);}
  55%   {-webkit-filter: drop-shadow(0px 12px 20px yellow);filter: drop-shadow(0px 12px 20px yellow);}
  65%   {-webkit-filter: drop-shadow(-10px 12px 21px yellow);filter: drop-shadow(-10px 12px 21px yellow);}
  80%   {-webkit-filter: drop-shadow(-12px -6px 23px yellow);filter: drop-shadow(-12px -6px 23px yellow)}
  90%   {-webkit-filter: drop-shadow(-3px -6px 25px yellow);filter: drop-shadow(-3px -6px 25px yellow)}
  100% {-webkit-filter: drop-shadow(0px 0px 32px yellow);filter: drop-shadow(0px 0px 32px yellow);}
}
@-webkit-keyframes fadeOut {
  0%   { opacity: 1;}
  100% { opacity: 0;}
}
@keyframes fadeOut {
  0%   { opacity: 1;}
  100% { opacity: 0;}
}
@-webkit-keyframes fadeIn {
  0%   { opacity: 0;}
  100% { opacity: 1;}
}
@keyframes fadeIn {
  0%   { opacity: 0;}
  100% { opacity: 1;}
}

@-webkit-keyframes slideIn {
    0%   { top: -210px;}
    100% { top: -30px;}
}

@keyframes slideIn {
    0%   { top: -210px;}
    100% { top: -30px;}
}

@media only screen and (max-width: 459px) {
  .iconBgContainer {
    -webkit-transform: scale(0.92);
            transform: scale(0.92);
  }
  .flavorMessage {
    -webkit-transform: scale(0.85);
            transform: scale(0.85);
  }
}
@media only screen and (max-width: 408px) {
  .iconBgContainer {
    -webkit-transform: scale(0.85);
            transform: scale(0.85);
  }
  .flavorMessage {
    -webkit-transform: scale(0.85);
            transform: scale(0.85);
  }
}

@media only screen and (max-width: 375px) {
    .button-content p {
        font-size: 2.75rem;
    }
  .iconBgContainer {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  .flavorMessage {
    -webkit-transform: scale(0.78);
            transform: scale(0.78);
  }
}
@media only screen and (max-width: 320px) {
    .button-content p {
      font-size: 2.75rem;
    }
  .iconBgContainer {
    -webkit-transform: scale(0.70);
            transform: scale(0.70);
  }
  .flavorMessage {
    -webkit-transform: scale(0.68);
            transform: scale(0.68);
  }
}
