.skeleton-container {
  width: 300px;
}
.skeleton-container .xs {
  width: 10%;
}
.skeleton-container .sm {
  width: 15%;
}
.skeleton-container .md {
  width: 30%;
}
.skeleton-container .lg {
  width: 60%;
}
.skeleton-container .xl {
  width: 100%;
}
.h10 {
  height: 10px;
}
.h15 {
  height: 15px;
}
.h30 {
  height: 30px;
}
.h60 {
  height: 60px;
}
.h120 {
  height: 120px;
}
.skeleton-container .line {
  float: left;
  margin: 5px;
  border-radius: 8px;
  background-image: linear-gradient(90deg, #f9f9f9 0px, #f4f4f4 40px, #f9f9f9 80px);
  background-size: 600px;
  animation: shine-lines 3s infinite ease-out;
}

.skeleton-container .line ~ .line {
  background-color: #f9f9f9;
}

.skeleton-container .clear {
  clear: left;
}

@keyframes shine-lines {
  0% {
    background-position: -100px;
  }
  40%, 100% {
    background-position: 500px;
  }
}
