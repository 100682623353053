.big-button-container {
    /* background-color: #008938; */
    display: flex;
    align-items: center;
    height: fit-content;
    flex-direction: column;
}

.button-bg-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.bigButton {
    width: 100%;
    height: 100%;
    z-index: 2;   
}

.buttonImg {
    height: auto;
    max-width: 410px;
    width: 100%;
}

.button-content {
    position: absolute;
    left: 50.5%;
    top: 49%;
    transform: translate(-50%,-50%) scale(1);
    pointer-events: none;
}

.button-content p {
    margin: 0;
    line-height: 0.85;
    color: white;
    font-size: 3rem;
}

.relative { position: relative; }
.absolute { 
  position: absolute; 
  left: 0 ;
  z-index:-1; 
}

.yellow {
    color: #fbc114 !important;
}

.prize-reveal {
  opacity: 0;
  position: absolute;
  left: 50%;
  top: 49%;
  transform: translate(-50%,-50%);
  pointer-events: none;
}

.prize-reveal img {
  max-width: 145px;
  max-height: 145px;
  /* width: 100%;
  height: auto; */
}
.prize-incoming {
    position: absolute;
    z-index: 3;
    top: -210px;
    display: none;
}

.prize-incoming img {
    max-width: 300px;
    width: 100%;
}

.enlarge-anim {
  animation: enlarge 0.6s ease-in-out infinite alternate;
  transform-origin: bottom left;
  transform: scale(1.05);
  position: absolute;
}
.pushed-anim {
  animation: pushed 0.35s cubic-bezier(0,.88,.96,1), glow 0.8s linear 1 forwards;
  transform-origin: center;
}

.fadeOut-anim {
  animation: fadeOut 0.3s ease-out 0.3s forwards, pushedText 0.35s cubic-bezier(0,.88,.96,1);
}
.fadeInText-anim {
  animation: fadeIn 0.1s ease-in 1s forwards;
  opacity: 0;
}

.fadeIn-anim {
  animation: fadeIn 0.1s ease-in 1s forwards;
}
.fadeOutSlow-anim {
  animation: fadeOut 1s ease-out forwards;
}
.slideIn-anim {
  animation: slideIn 0.5s ease-out 0.5s forwards;
  display: block!important;
}


.tandc, .tandc a, .flavorMessage {
    color: white;
} 

.tandc p {
  margin: 0 auto;
  padding: 0 14px;
}

.flavorMessage {
    font-weight: bold; 
    font-size: 1rem;
    position: relative;
    bottom: 5px;
    height: 165px;
}

.apostroContainer {
    display: flex;
    flex-direction: row;
}

.apostroContainer:nth-child(1){
    align-items: flex-end;
}
.apostroContainer:nth-child(1) span {
    line-height: 1;
}
.apostroContainer:nth-child(2){
    align-items: center;
}
.apostroContainer:nth-child(3){
    align-items: flex-start;
}

.apostroContainer:nth-child(3) span {
    line-height: 0.6;
}

.apostroContainShort {
    display: flex;
    flex-direction: row;
}

.apostroContainShort:nth-child(1){
    align-items: flex-end;
    margin-bottom: 8px;
}
.apostroContainShort:nth-child(1) span {
    line-height: 1;
}

.apostroContainShort:nth-child(2){
    align-items: flex-start;
}

.apostroContainShort:nth-child(2) span {
    line-height: 0.6;
}

.white{
    align-items: flex-start;
}
.white span {
    line-height: 0.6;
    color: white;
}

.centerTextContainer {
    margin-bottom: 5px;
}

.centerText{
    line-height: 0.6;
}

.flavorText {
    font-size: 34px;
}

.opacityzero {
    opacity: 0;
}

.opacityshow {
  opacity: 1;
}

.flavorTextMiddle {
    display: flex;
    line-height: 1;
    margin-bottom: 6px;
    color: #fbc114;
}

.apostroImg1 {
    width: 60px;
    height: 60px;
}
.apoOpen {
    margin-right: 10px;
}
.apoClose {
    margin-left: 10px;
}

.breakspace {
  margin-right: 5px;
}

/*=========================*/
/*===== Carousel BG =======*/
/*=========================*/

.buttonContent {
    width: 60px;
    height: 60px;
    font-size: 0.5rem;
}

/*=====================*/
/*===== Icon BG =======*/
/*=====================*/

.iconBgContainer {
    max-width: 400px;
    height: auto;
    position: absolute;
    top: 48%;
    z-index: -2;
    left: 48.5%;
}

.iconBg {
    position: absolute;
    width: auto;
    max-height: 90px;
    max-width: 90px;
    left: calc(50% - 25px);
    top: calc(50% - 25px);
    transform-origin: center;
    animation: jiggle 1.5s cubic-bezier(.46,.16,.47,.84) infinite alternate;
}

.iconBg:nth-child(1) {
    transform: translate(148%,96%) rotate(13deg);
    max-height: 110px;
    max-width: 100px;
}
.iconBg:nth-child(2) {
    transform: translateX(273%) translateY(115%) rotate(344deg);
    max-height: 78px;
    max-width: 88px;
}
.iconBg:nth-child(3) {
    transform: translateX(306%) translateY(143%) rotate(335deg)
}
.iconBg:nth-child(4) {
    transform: translateY(192%) rotate(-13deg);
}
.iconBg:nth-child(5) {
    max-height: 90px;
    max-width: 80px;
    transform: translateX(-113%) translateY(329%) rotate(-50deg);
}
.iconBg:nth-child(6) {
    transform: translateX(-234%) translateY(230%) rotate(-16deg);
    max-height: 72px;
    max-width: 72px;
}
.iconBg:nth-child(7) {
    transform: translate(-300%,72%);
    max-height: 62px;
    max-width: 62px;
}
.iconBg:nth-child(8) {
    transform: translate(-193%,-50%) rotate(13deg);
    max-width: 90px;
}
.iconBg:nth-child(9) {
    transform: translate(-199%,-233%) rotate(35deg);
}
.iconBg:nth-child(10) {
    transform: translate(-118%,-242%) rotate(-16deg);
    max-width: 85px;
}
.iconBg:nth-child(11) {
    transform: translate(11%,-230%) rotate(10deg);
    max-width: 82px;
}
.iconBg:nth-child(12) {
    transform: translate(146%,-283%) rotate(35deg);
    max-width: 76px;
}
.iconBg:nth-child(13) {
    transform: translate(190%,-88%) rotate(35deg);
    max-width: 74px;
}

.iconBg:nth-child(14), .iconBg:nth-child(15), .iconBg:nth-child(16) {
  display: none;
}

@keyframes jiggle {
  0%   { left:-24px; top:-18px;}
  25%  { left:-27px; top:-20px;}
  50%  { left:-25px; top:-23px;}
  75%  { left:-23px; top:-19px;}
  100% { left:-22px; top:-24px;}
}

@keyframes enlarge {
  0%   { transform: scale(1.05);}
  100% { transform: scale(1.3);}
}
@keyframes pushed {
  0%   { transform: scale(1);}
  50% { transform: scale(0.93);}
  100% { transform: scale(1);}
}
@keyframes pushedText {
  0%   { transform: scale(1) translate(-50%,-50%);}
  50% { transform: scale(0.93) translate(-50%,-50%);}
  100% { transform: scale(1) translate(-50%,-50%);}
}
@keyframes glow {
  0%   {filter: drop-shadow(0px 0px 0px yellow);}
  10%   {filter: drop-shadow(0px -9px 20px yellow);}
  20%   {filter: drop-shadow(10px -8px 20px yellow);}
  40%   {filter: drop-shadow(10px 10px 20px yellow);}
  55%   {filter: drop-shadow(0px 12px 20px yellow);}
  65%   {filter: drop-shadow(-10px 12px 21px yellow);}
  80%   {filter: drop-shadow(-12px -6px 23px yellow)}
  90%   {filter: drop-shadow(-3px -6px 25px yellow)}
  100% {filter: drop-shadow(0px 0px 32px yellow);}
}
@keyframes fadeOut {
  0%   { opacity: 1;}
  100% { opacity: 0;}
}
@keyframes fadeIn {
  0%   { opacity: 0;}
  100% { opacity: 1;}
}

@keyframes slideIn {
    0%   { top: -210px;}
    100% { top: -30px;}
}

@media only screen and (max-width: 459px) {
  .iconBgContainer {
    transform: scale(0.92);
  }
  .flavorMessage {
    transform: scale(0.85);
  }
}
@media only screen and (max-width: 408px) {
  .iconBgContainer {
    transform: scale(0.85);
  }
  .flavorMessage {
    transform: scale(0.85);
  }
}

@media only screen and (max-width: 375px) {
    .button-content p {
        font-size: 2.75rem;
    }
  .iconBgContainer {
    transform: scale(0.8);
  }
  .flavorMessage {
    transform: scale(0.78);
  }
}
@media only screen and (max-width: 320px) {
    .button-content p {
      font-size: 2.75rem;
    }
  .iconBgContainer {
    transform: scale(0.70);
  }
  .flavorMessage {
    transform: scale(0.68);
  }
}